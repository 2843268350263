import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import SportsRunNormalSmall from '@strava/icons/SportsRunNormalSmall';
import SportsBikeNormalSmall from '@strava/icons/SportsBikeNormalSmall';
import SportsRunNormalXsmall from '@strava/icons/SportsRunNormalXsmall';
import SportsBikeNormalXsmall from '@strava/icons/SportsBikeNormalXsmall';
import ActivityElevationNormalSmall from '@strava/icons/ActivityElevationNormalSmall';
import ActivityElevationNormalXsmall from '@strava/icons/ActivityElevationNormalXsmall';
import AchievementsLocalLegendNormalSmall from '@strava/icons/AchievementsLocalLegendNormalSmall';

import { matchMedia } from 'utils/media-query';

import styles from './styles.scss';

const ProgressDataView = ({
  entries,
  pathColor,
  trailColor,
  labelSingle,
  labelMultiple
}) => {
  const resultCardClick = (destination) => {
    window.location.href = destination;
  };

  const sportTypeIcon = (icon) => {
    if (matchMedia.screenSm()) {
      if (icon === 'icon-bike') {
        return <SportsBikeNormalXsmall />;
      }
      return <SportsRunNormalXsmall />;
    }
    if (icon === 'icon-bike') {
      return <SportsBikeNormalSmall />;
    }
    return <SportsRunNormalSmall />;
  };

  const elevationIcon = () => {
    if (matchMedia.screenSm()) {
      return <ActivityElevationNormalXsmall />;
    }
    return <ActivityElevationNormalSmall />;
  };

  const label = (effortCount) => {
    return effortCount === 1 ? labelSingle : labelMultiple;
  };

  return (
    <div className={styles.resultCardContainer}>
      {entries.map((result) => (
        <div
          className={styles.resultCard}
          key={result.id}
          onClick={() => resultCardClick(result.destination)}
          onKeyDown={() => resultCardClick(result.destination)}
          role="link"
          tabIndex={0}
        >
          <div className={styles.progressBar}>
            <CircularProgressbarWithChildren
              value={result.percentComplete}
              strokeWidth={6}
              styles={buildStyles({
                pathTransition: 'none',
                pathColor,
                trailColor
              })}
            >
              <div className={`${styles.count}`}>{result.effortCount}</div>
              <div className={styles.label}>{label(result.effortCount)}</div>
            </CircularProgressbarWithChildren>
          </div>
          <div className={styles.segment}>
            <div className={styles.title}>
              {result.isLcl && (
                <div className={styles.localLegendIcon}>
                  <AchievementsLocalLegendNormalSmall fill={pathColor} />
                </div>
              )}
              <div>{result.name}</div>
            </div>
            <div className={styles.details}>
              <div className={styles.sportTypeIcon}>
                {sportTypeIcon(result.icon)}
              </div>
              <div className={styles.distance}>{result.distance}</div>
              <div className={styles.elevationIcon}>{elevationIcon()}</div>
              <div className={styles.elevation}>{result.elevation}</div>
            </div>
          </div>
          <div className={styles.divider} />
        </div>
      ))}
    </div>
  );
};

ProgressDataView.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      effortCount: PropTypes.number.isRequired,
      destination: PropTypes.string.isRequired,
      distance: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      elevation: PropTypes.string.isRequired,
      isLcl: PropTypes.bool.isRequired
    })
  ).isRequired,
  pathColor: PropTypes.string.isRequired,
  trailColor: PropTypes.string.isRequired,
  labelSingle: PropTypes.string.isRequired,
  labelMultiple: PropTypes.string.isRequired
};

export default ProgressDataView;
