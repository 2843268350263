import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import MediaQuery, { breakpoints } from 'utils/media-query';
import styles from './styles.scss';

const EmptyState = ({
  text,
  buttonLabel,
  buttonDestination,
  backgroundImage,
  backgroundImageMobile
}) => {
  const handleButtonClick = () => {
    window.location.href = buttonDestination;
    return false;
  };

  return (
    <div className={styles.emptyStateContainer}>
      <MediaQuery minWidth={breakpoints.screenSm}>
        <div
          className={styles.backgroundImage}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className={styles.textDesktop}>{text}</div>
          <div className={styles.button}>
            <Button variant="primary" onClick={handleButtonClick}>
              {buttonLabel}
            </Button>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={breakpoints.screenSm}>
        <div
          className={styles.backgroundImage}
          style={{ backgroundImage: `url(${backgroundImageMobile})` }}
        />
        <div className={styles.textMobile}>{text}</div>
        <div className={styles.button}>
          <Button variant="primary" onClick={handleButtonClick}>
            {buttonLabel}
          </Button>
        </div>
      </MediaQuery>
    </div>
  );
};

EmptyState.propTypes = {
  text: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonDestination: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  backgroundImageMobile: PropTypes.string.isRequired
};

export default EmptyState;
